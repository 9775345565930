import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import emailjs from 'emailjs-com';

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { MapContainer, GeoJSON, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
export const Inquiries = () => {
  const [name, setName] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || selectedState === '') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      setStateError(selectedState === ''); // Set the state error based on the selected state
    } else {
    // Set your EmailJS service ID, template ID, and public key
    const serviceId = 'service_7orttlg';
    const templateId = 'template_y3u8fsa';
    const publicKey = 'u6klCYKt6dEhcrabY';

    emailjs.send(serviceId, templateId, {
      from_name: name,
      from_lastname: lname,
      from_email: email,
      phone: phone,
      message: message,
      state: selectedState,
    }, publicKey)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error('Email failed to send:', error);
        setIsSubmitted(false);
      });
    }
  };

  const [validated, setValidated] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [usStatesData, setUSStatesData] = useState(null);
  const [hoveredState, setHoveredState] = useState(null);
  const [activeState, setActiveState] = useState(null); // Track the active (clicked) state


  useEffect(() => {
    const fetchUSStatesData = async () => {
      try {
        const response = await axios.get(
          "https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json"
        );
        // Filter out Alaska
        const filteredData = response.data.features.filter(
          (feature) => feature.properties.name !== "Alaska"
        );
        setUSStatesData(filteredData);
      } catch (error) {
        console.error("Error fetching US states data:", error);
      }
    };

    fetchUSStatesData();
  }, []);
  // List of allowed states
  const allowedStates = ['Massachusetts', 'Colorado', 'California'];


  // Event handler for state click
  const handleStateClick = (e) => {
    const { name } = e.target.feature.properties;

    // Check if the clicked state is allowed
    if (allowedStates.includes(name)) {
      setSelectedState(name || '');
      setStateError(false); // Reset the state error when the state is selected
      setActiveState(name); // Set the active state
    } else {
      // If the state is not allowed, show a popup or set an error state
      // You can implement your own logic here (e.g., show a modal, set an error state)
      // alert(`Sorry, inquiries are not available for ${name}`);
      setSelectedState(name || '');
      setStateError(false);
      setActiveState(name); // Reset the active state

      // previous
       // alert(`Sorry, inquiries are not available for ${name}`);
      // setSelectedState('');
      // setStateError(true);
      // setActiveState(null); 
    }
  };

  // Event handler for state hover
  const handleStateHover = (e) => {
    const { name } = e.target.feature.properties;
    setHoveredState(name || 'Unknown State');
  };

  // Event handler for mouseout
  const handleMouseOut = () => {
    setHoveredState(null);
  };
  const maxZoom = window.innerWidth < 768 ? 2.5 : 3.5;
  const drag = window.innerWidth < 768 ? true : false;
  const zoom = window.innerWidth < 768 ? true : false;

  return (
    <>
      <section className="InquiriesSection">
        <div className="container">
          <div className="contactUs border-gradient inputBg border-gradient-purple">
            <div>
              <div className="contactUsTitle">
                <h2>For inquiries, have our sales engineers call you</h2>
                <p>Kindly fill out the following form</p>
              </div>
              {isSubmitted ? (
        <div>
          <h2 style={{textAlign:'center'}}>Thank you for submitting the form!</h2>
          <p>We will get back to you as soon as possible.</p>
        </div>
      ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row" style={{textAlign:'left'}}>
                  <div className="col-lg-6 oursolarworker">
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label className="text-white">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          value={name} onChange={(e) => setName(e.target.value)} required
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Form.Label className="text-white">
                          Last name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          value={lname} onChange={(e) => setLname(e.target.value)} required
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustomPhone"
                      >
                        <Form.Label className="text-white">Phone</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            placeholder="Phone"
                            aria-describedby="inputGroupPrepend"
                            value={phone} onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a phone number.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustomUsername"
                      >
                        <Form.Label className="text-white">Email</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="email"
                            placeholder="E-mail"
                            aria-describedby="inputGroupPrepend"
                            value={email} onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a E-mail.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom03"
                      >
                        <Form.Label className="text-white">Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write your message.."
                          cols={30}
                          rows={5}
                          value={message} onChange={(e) => setMessage(e.target.value)} 
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a message.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Check
                          required
                          style={{ color: "white" }}
                          label="Agree to terms and conditions"
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                        />
                      </Form.Group>
                    </Row>
                  </div>
                  <div className="col-lg-6 border-footer">
                    <div className="mapSec">
                      <h3>Select Your State</h3>
                      <div className='map'>
                      <MapContainer
                      center={[40.8, -96]}
                      zoom={3.5}
                      maxZoom={maxZoom} // Set maximum zoom level based on screen size
                      bounds={[[24.396308, -125.0], [49.384358, -66.93457]]} // Bounds excluding Alaska
                      style={{ height: '500px', width: '100%' }}
                      scrollWheelZoom={zoom}
                      dragging={drag}
                      >
                        {usStatesData &&
                          usStatesData.map((feature) => (
                            <GeoJSON
                              key={feature.id}
                              data={{
                                type: 'FeatureCollection',
                                features: [feature],
                              }}
                              style={(feature) => ({
                                fillColor: (feature.properties.name === hoveredState || feature.properties.name === activeState) ? '#ff6f00' : (allowedStates.includes(feature.properties.name) ? 'gray' : 'black'),
                                weight: 1,
                                color: 'black',
                                fillOpacity: 0.7,
                              })}
                              onEachFeature={(feature, layer) => {
                                layer.on({
                                  mouseover: handleStateHover,
                                  mouseout: handleMouseOut,
                                  click: handleStateClick,
                                });
                              }}
                            >
                              <Tooltip className='opoppp'>{hoveredState}</Tooltip>
                            </GeoJSON>
                          ))}
                      </MapContainer>
                    </div>
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom03"
                      >
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            placeholder="Selected State"
                            readOnly
                            style={{ marginBottom: "10px" }}
                            value={selectedState} 
                            onChange={(e) => setSelectedState(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Select State.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      {stateError && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginBottom: "10px",
                          }}
                        >
                          Please select a state.
                        </div>
                      )}
                      {/* <Form.Control
                  type="text"
                  placeholder="Selected State"
                  value={selectedState}
                  readOnly
                  style={{ marginBottom: '10px' }}
                  feedback="You must agree before submitting."
          feedbackType="invalid"
                /> */}
                      <Button
                        type="submit"
                        className="btn-inquiries"
                      >
                        Submit form
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
