import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import emailjs from 'emailjs-com';

export const HaveAQuerry = () => {
  const [validated, setValidated] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   setValidated(true);
  // };

  const handleAmountClick = (amount) => {
    setAmount(amount);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
    // Set your EmailJS service ID, template ID, and public key
    const serviceId = 'service_7orttlg';
    const templateId = 'template_z4a2boi';
    const publicKey = 'u6klCYKt6dEhcrabY';

    emailjs.send(serviceId, templateId, {
      from_name: name,
      from_email: email,
      phone: phone,
      message: message,
      amount: amount,
    }, publicKey)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error('Email failed to send:', error);
        setIsSubmitted(false);
      });
    }
  };
  return (
    <>
      <section className="oursolarworkWind">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="oursolarworkfirst">
                <h2 className="text-white gradient">Investor Options</h2>
              </div>
              <Row
                style={{ alignItems: "end", paddingBottom: "9px" }}
                className="mb-3 name"
              >
                <div className="col-lg-4 col-6">
                  <div className="investment " onClick={() => handleAmountClick("$50K to $200K")}>
                    <h4>$50K to $200K</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="investment " onClick={() => handleAmountClick("$200K to $1M")}>
                    <h4>$200K to $1M</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="investment" onClick={() => handleAmountClick("$1M to $10M")}>
                    <h4>$1M to $10M</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="investment" onClick={() => handleAmountClick("$10M to $100M")}>
                    <h4>$10M to $100M</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="investment" onClick={() => handleAmountClick("$100M to $500M")}>
                    <h4>$100M to $500M</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="investment" onClick={() => handleAmountClick("Other")}>
                    <h4>Other</h4>
                  </div>
                </div>
              </Row>
            </div>
          </div>
          <section className="secondSection">
            <div className="container">
              <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='secondSectionRight'>
                    <h2 className='text-white'>WITH VTV ENERGY</h2>
                    <p className='text-white'>
                      Guaranteed return of investment within 6 years and 24 years of profit per install, it's just that simple! <br /><br />
                      With VTV Energy, we open the gate for international and local investors to participate in the gains of the energy industry. <br /><br />
                      We currently have potential projects across the United States, specifically within California, Arizona, and Texas. A once in a lifetime opportunity to partake in!<br /><br />
                      This is your gateway into the energy industry. <br /><br />
                      VTV Energy <br />
                      For The People
                    </p>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='secondSectionLeft'>
                    <img style={{ objectFit: 'contain', width: '100%' }} src={require("../assets/images/withvtv.png")} alt='residential solar panel installation' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Modal className="amountModal" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please fill this form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isSubmitted ? (
        <div className="text-center">
          <h2 style={{textAlign:'center',color:'white'}}>Thank you for submitting the form!</h2>
          <p style={{color:'white'}}>We will get back to you as soon as possible.</p>
        </div>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row" style={{textAlign:'left'}}>
                  <div className="oursolarworker">
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                      >
                        
                        <Form.Label className="text-white">
                          Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" Enter your Name....."
                          value={name} onChange={(e) => setName(e.target.value)} required
                        />
                        <Form.Control
                          type="hidden"
                          placeholder="Amount"
                          value={amount} onChange={(e) => setAmount(e.target.value)} required
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      
                     
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomUsername"
                      >
                        <Form.Label className="text-white">Email</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="email"
                            placeholder="example@example.com"
                            aria-describedby="inputGroupPrepend"
                            value={email} onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a E-mail.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomPhone"
                      >
                        <Form.Label className="text-white">Phone</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="text"
                            placeholder="+1 012 3456 7890"
                            aria-describedby="inputGroupPrepend"
                            value={phone} onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a phone number.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom03"
                      >
                        <Form.Label className="text-white">Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write your message.."
                          cols={30}
                          rows={5}
                          value={message} onChange={(e) => setMessage(e.target.value)} 
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a message.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        style={{ textAlign: "left" }}
                      >
                         <Form.Check
        required
        style={{ color: 'white' }}
        label={
          <span>
            I agree to the terms and conditions displayed under{' '}
            <a href="https://vtv.com" target="_blank" style={{ color: 'white' }}>VTV Energy</a>.
          </span>
        }
        feedback="You must agree before submitting."
        feedbackType="invalid"
      />
                      </Form.Group>
                    </Row>
                  </div>
                  <Modal.Footer>
          <Button variant="secondary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
                </div>
              </Form>
      )}
        </Modal.Body>
        
      </Modal>
    </>
  );
};
