import React from 'react'
import { Link } from 'react-router-dom'

export const Hero = () => {
  return (
    <>
    <section className="hero">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-sm-12 hero-leftMain">
            <div className='herofirst'>
              <h1 className='text-white'>Turn-Key Engineering <br/>Solutions</h1>
              <p className='px-6 paraBG'>
              At VTV Energy, we offer the highest quality solar installations at the best pricing in the market
guaranteed.
              </p>
              <Link to="/contact-us"><button className="buttonMainHomeBanner">Let’s Go!  Zero Carbon Footprint</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
