import React from 'react'

export const VtvEnergy = () => {
  return (
    <>
    <section className="vtvEnergy">
        <div className="container bgvtv">
            <h2 className="text-center">VTV ENERGY</h2>
        </div>
    </section>
    </>
  )
}
