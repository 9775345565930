import React, { useState,useEffect } from 'react'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { Navigation, Pagination } from 'swiper/modules';
// import { Swiper, SwiperSlide } from "swiper/react";
import Flickity from 'flickity';
import 'flickity/css/flickity.css';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export const FeatureProjects = () => {
    const [activeTab, setActiveTab] = useState("Commercial Projects");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const flkty = new Flickity('.carousel', {
      cellAlign: 'center',
      pageDots: false,
      groupCells: '20%',
      lazyLoad: 2,
      selectedAttraction: 0.03,
      friction: 0.15
    });

    return () => {
      flkty.destroy();
    };
  }, []);

  const renderImage = () => {
    switch (activeTab) {
      case "Commercial Projects":
        return (
            <div className="carousel">
      <div className="carousel-cell">
               
               <img
                 className="img-fluid"
                 style={{height:'531px',objectFit:'cover',width:'100%'}}
                 src={require("../assets/images/systemDesign.png")}
                 alt=""
               />
          
      </div>

      <div className="carousel-cell">               
               <img
                 className="img-fluid"
                 style={{height:'531px',objectFit:'cover',width:'100%'}}
                 src={require("../assets/images/second.jpg")}
                 alt=""
               />
      </div>
      <div className="carousel-cell">
                <img
                  className="img-fluid"
                  style={{height:'531px',objectFit:'cover',width:'100%'}}
                  src={require("../assets/images/solarimage1.jpg")}
                  alt=""
                />
      </div>
      <div className="carousel-cell">
                <img
                  className="img-fluid"
                  style={{height:'531px',objectFit:'cover',width:'100%'}}
                  src={require("../assets/images/solarimage2.jpg")}
                  alt=""
                />
      </div>
      
     
      {/* Other carousel cells here */}
    </div>
        );
      case "Residential Projects":
        return (
          <div className="carousel">
          <div className="carousel-cell">
                   
                   <img
                     className="img-fluid"
                     style={{height:'531px',objectFit:'cover',width:'100%'}}
                     src={require("../assets/images/solarimage5.jpg")}
                     alt=""
                   />
              
          </div>
    
          <div className="carousel-cell">
                   <img
                     className="img-fluid"
                     style={{height:'531px',objectFit:'cover',width:'100%'}}
                     src={require("../assets/images/solarimage6.jpg")}
                     alt=""
                   />
          </div>
          <div className="carousel-cell">                    
                    <img
                      className="img-fluid"
                      style={{height:'531px',objectFit:'cover',width:'100%'}}
                      src={require("../assets/images/solarimage3.jpg")}
                      alt=""
                    />
          </div>
          <div className="carousel-cell">                    
                    <img
                      className="img-fluid"
                      style={{height:'531px',objectFit:'cover',width:'100%'}}
                      src={require("../assets/images/solarimage4.jpg")}
                      alt=""
                    />
          </div>
          
         
          {/* Other carousel cells here */}
        </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
    <section className="featureProjects">
    <div className='container'>
    <h2>Featured Projects</h2>
    <div className='row'>
        <div className='col-lg-4'>
    <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={handleTabChange}
                className="mb-3"
              >
                <Tab eventKey="Commercial Projects" title="Commercial Projects">
                  
                </Tab>
                <Tab eventKey="Residential Projects" title="Residential Projects">
                  
                </Tab>
              
              </Tabs>
              </div>
                <div className='col-lg-8'>
                {renderImage()}
                    </div>
    </div>
    </div>
    </section>
    </>
  )
}
