import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowAltCircleUp, faEnvelope, far } from '@fortawesome/free-regular-svg-icons'
import { faL, faPhone, fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Footer = () => {
    const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    // Show the button when user scrolls beyond 400px from the top
    if (window.scrollY > 400) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Smooth scrolling behavior
    });
  };

  // Add scroll event listener when component mounts
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
    <section className="footerSection">
        <div className='container'>
        <div className='forborderFooter full-withradius'>
        <div className='row'>
                <div className='col-lg-3'>
                    <div className='footerLogo'>
                        <img width='200px' src={require('../assets/logo/headerLogo.png')} alt='logo' />
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='footerMenu'>
                            <h4 className='cont'>Useful Links</h4>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/solar-energy'>Solar Energy</Link></li>
                            <li><Link to='/investor-relations'>Investor Relations</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='footerMenu'>
                            <h4 className='cont'>Explore More</h4>
                        <ul>
                            <li><Link to='/Why-VTV'>Why VTV</Link></li>
                            <li><Link to='/contact-us'>Contact</Link></li>
                            {/* <li><Link to='#'>FAQs</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='dert'>
                    <div className='footerContact footerMenu'>
                    <ul style={{padding:'0px'}}>
                    <h4 className='cont'>Contact</h4>
                            </ul>
                        {/* <p>Address: 1234 Street Name, City Name, United States</p>
                        <p>Phone: +123 456 7890</p> */}
                        <p style={{display:'flex',gap:'5px',alignItems:'center',marginBottom:'0px'}}>
                        <img src={require('../assets/images/email.png')} />
                            <a href='mailto:info@vtvenergy.com' style={{color:'white'}}> 
                            info@vtvenergy.com
                            </a>
                        </p>
                        <p style={{display:'flex',gap:'5px',alignItems:'center',marginBottom:'0px'}}>
                        <FontAwesomeIcon style={{padding: '3px', background: 'white', color: '#ff6500', borderRadius: '2px', marginLeft: '2px'}} className='icon phone' icon={faPhone} />
                            <a href='tel:+16097899242' style={{color:'white'}}> 
                            +16097899242
                            </a>
                        </p>
                    </div>
                    <div className='socialIcon'>
                        <a href='https://www.linkedin.com/company/vative-solutions/' target="_blank"><FontAwesomeIcon className='icon' icon={faLinkedin} /></a>
                        <a href='https://www.instagram.com/vtvenergy/' target="_blank"><FontAwesomeIcon className='icon' icon={faInstagram} /></a>
                        {/* <a href='#'><FontAwesomeIcon className='icon' icon={faTwitter} /></a> */}
                    </div>
                    </div>
                    <div className='fixed-buttons'>
                    <a href='tel:+16097899242'>
                    <FontAwesomeIcon className='icon phone-static' icon={faPhone} />
                    </a>
                    {showButton && (
        
                    <FontAwesomeIcon onClick={scrollToTop} className='icon arrowup-static' icon={faArrowAltCircleUp} />
        
      )}
                    </div>
                </div>
            </div>
           </div>
           {/* <div class="full-withradius border">Gradient border with border radius</div> */}

        </div>
    </section>
    </>
  )
}
